import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import Layout from '../templates/Layout/Layout';
import OnboardingView from '../views/OnboardingView';
import { LoadingSpinner } from '@rabbit/elements/shared-components';
import ROUTE_NAME from './url-constants';
import ModalTermsConditions from '../components/molecules/ModalTermsConditions/ModalTermsConditions';
import { FileStorageProviderWrapper } from '@rabbit/bizproc/react';
import { useTranslation } from 'react-i18next';
const PrivateRoutes = (props: any) => {
  const location = useLocation();
  const contextValues = useContext(UserContext);
  const { t } = useTranslation();

  const [cancelable, setCancelable] = useState(false);
  useEffect(function () {
    const timeout = setTimeout(function () {
      setCancelable(true);
    }, 7200);

    return function () {
      clearTimeout(timeout);
    };
  }, []);

  const NLIC_StopGetIdentity = window.localStorage.getItem(
    'NLIC_StopGetIdentity'
  );
  if (Number(NLIC_StopGetIdentity) < Date.now() / 1000) {
    window.localStorage.removeItem('NLIC_StopGetIdentity');
  }

  // Check if user is in the middle of a passwordless sign-in
  if (window.localStorage.getItem('emailForSignIn'))
    return (
      <LoadingSpinner
        size="lg"
        isFullScreen
        label={t('Click the button in your latest email to log in.')}
        action={{
          actionFn: () => {
            window.localStorage.removeItem('emailForSignIn');
            window.location.reload();
          },
          actionLabel: 'Stuck? Click here to return to Login.',
        }}
      />
    );

  if (
    contextValues !== null &&
    !contextValues.isLoading &&
    ((contextValues.consumerPersonaId === 'NOTALLOWED' &&
      contextValues.consumerPersonaData === null) ||
      contextValues.user === null)
  ) {
    if (window.location.pathname !== '/auth') {
      localStorage.setItem('redirectUrl', window.location.pathname);
    }
    return <Navigate to={ROUTE_NAME.AUTH} />;
  }

  if (!contextValues?.consumerPersonaData) {
    if (cancelable) {
      return (
        <LoadingSpinner
          size="lg"
          isFullScreen
          label="Loading..."
          action={{
            actionLabel: 'Stuck? Click here to return to Login.',
            actionFn: () => {
              contextValues?.handleSignout();
            },
          }}
        />
      );
    }
    return <LoadingSpinner size="lg" isFullScreen label="Loading..." />;
  }

  if (
    //!contextValues?.consumerPersonaData?.dob ||
    contextValues?.consumerPersonaData &&
    contextValues?.consumerPersonaData.fullname &&
    (!contextValues?.consumerPersonaData?.splitname?.first ||
      !contextValues?.consumerPersonaData?.splitname?.last)
  ) {
    const providerId = contextValues?.user?.providerData[0]?.providerId;
    /*
     * ProviderId === 'password' -> for email login
     * ProviderId === 'google.com' || 'apple.com' -> for social login
     */
    if (location.pathname === ROUTE_NAME.COMPLETE_PROFILE) {
      return <Outlet />;
    } else {
      if (providerId !== 'google.com' && providerId !== 'apple.com') {
        return <Navigate to={ROUTE_NAME.COMPLETE_PROFILE} />;
      }
    }
  } else {
    if (
      window.localStorage.getItem('LRP_VendableId') &&
      !String(location.pathname).includes(ROUTE_NAME.PRODUCTS_PREVIEW)
    ) {
      const LRP_VendableId = window.localStorage.getItem('LRP_VendableId');
      return (
        <Navigate
          to={ROUTE_NAME.PRODUCTS_PREVIEW + '/' + LRP_VendableId + '/register'}
        />
      );
    }
  }

  //check if user has dob and the pathname is /complete-profile
  if (
    contextValues?.consumerPersonaData?.splitname?.first &&
    location.pathname === ROUTE_NAME.COMPLETE_PROFILE
  ) {
    if (contextValues.consumerPersonaData.isOnboarded === false) {
      return <OnboardingView />;
    }
    const redirectUrl = localStorage.getItem('complete_profile_redirect_url');
    if (redirectUrl) {
      localStorage.removeItem('complete_profile_redirect_url');
      return <Navigate to={redirectUrl} />;
    }
    return <Navigate to={ROUTE_NAME.DASHBOARD} />;
  }

  //Not to show Navbar and container of max 1024px
  if (location.pathname === ROUTE_NAME.PRODUCTS_SCAN) return <Outlet />;

  const redirectUrl = localStorage.getItem('redirectUrl');
  if (redirectUrl && !String(location.pathname).includes(redirectUrl)) {
    localStorage.removeItem('redirectUrl');
    window.location.href = window.location.origin + redirectUrl;
  }

  return (
    <FileStorageProviderWrapper>
      <ModalTermsConditions
        consumerPersonaData={contextValues?.consumerPersonaData}
        consumerPersonaId={contextValues?.consumerPersonaId || ''}
      />
      <Layout>
        <Outlet />
      </Layout>
    </FileStorageProviderWrapper>
  );
};

export default PrivateRoutes;
