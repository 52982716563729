import { useContext, useState } from 'react';
import {
  AuthContainer,
  StepsProgress,
} from '@rabbit/elements/shared-components';
import NLICStart from '../../templates/NonLoggedInClaim/NLICStart';
import VendableSearchContainer from '../../templates/VendableSearchContainer/VendableSearchContainer';
import VendablePreviewView from '../vendables/preview/VendablePreviewView';
import NLICRegisterHolding from '../../templates/NonLoggedInClaim/NLICRegisterHolding';
import EmailAuth from '../../templates/Auth/EmailAuth';
import EmailSent from '../../templates/Auth/EmailSent';
import NLICAccountCreator from '../../templates/NonLoggedInClaim/NLICAccountCreator';
import HoldingSingleClaimView from '../holdings/claim-creation/HoldingSingleClaimView';
import { NLICCompleted } from '../../templates/NonLoggedInClaim/NLICCompleted';
import {
  DocumentTextIcon,
  IdentificationIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { AppContext } from '@rabbit/app-context';

type SwitchProps = {
  currentStep: number;
  data: any;
  onChange?: any;
};

const stepsNoGoBack = [1, 5, 6, 7, 8, 9];
const stepsNoProgress = [1, 5, 9];

interface NonLoggedInClaimViewProps {
  step?: number;
}
export function NonLoggedInClaimView({ step }: NonLoggedInClaimViewProps) {
  const [currentStep, setCurrentStep] = useState(
    step ? { step: step, data: null } : { step: 1, data: null }
  );
  const { tenantInfo } = useContext(AppContext);

  const onChangeStep = (step_in: number, data: any = null) => {
    setCurrentStep({ ...currentStep, step: step_in, data: data });
    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const onGoBack = () => {
    if (currentStep.step !== 1)
      onChangeStep(currentStep.step - 1, currentStep.data);
    if (currentStep.step === 4 || currentStep.step === 99) onChangeStep(2);
  };

  const steps = [
    {
      Icon: MagnifyingGlassIcon,
      completed: currentStep.step > 3,
    },
    {
      Icon: IdentificationIcon,
      completed: currentStep.step > 6,
    },
    {
      Icon: DocumentTextIcon,
      // Is never true, but we need to show the icon
      completed: false,
    },
  ];

  const getTitle = (step: number) => {
    switch (step) {
      case 1:
        return undefined;
      case 2:
      case 3:
        return 'Find your product';
      case 4:
        return 'Create account';
      case 5:
        return 'Confirm your email';
      case 6:
        return 'Complete your account';
      case 7:
      case 8:
        return 'Submit claim';
      case 99:
        return 'Scan a barcode';
    }
  };

  const Switch: React.FC<SwitchProps> = ({ currentStep, data, onChange }) => {
    switch (currentStep) {
      case 1:
        return <NLICStart onChange={onChange} data={data} />;
      case 2:
        return <VendableSearchContainer onChangeStep={onChange} />;
      case 3:
        return (
          <VendablePreviewView
            vendableId={data?.docid}
            onChangeStep={onChange}
          />
        );
      case 4:
        return (
          <EmailAuth
            onChange={(user: any) => onChange(5, { email: user })}
            isNLIC
            setIsLoading={setIsLoading}
          />
        );
      case 5:
        return <EmailSent onChange={() => onChange(4)} data={data} />;
      case 6:
        return (
          <NLICAccountCreator
            onChangeStep={(vendableId) => onChange(7, { vendableId })}
            setIsLoading={setIsLoading}
          />
        );
      case 7:
        return (
          <NLICRegisterHolding
            vendableId={data?.vendableId}
            onChangeStep={(holding_id) => onChange(8, { holding_id })}
            setIsLoading={setIsLoading}
          />
        );
      case 8:
        return (
          <HoldingSingleClaimView
            holding_id={data?.holding_id}
            onChangeStep={(claimData) => onChange(9, { claimData })}
          />
        );
      case 9:
        return <NLICCompleted data={data?.claimData} />;
      default:
        return null;
    }
  };

  return (
    <AuthContainer
      title={getTitle(currentStep.step)}
      goBack={
        !stepsNoGoBack.includes(currentStep.step) ? () => onGoBack() : null
      }
      background={currentStep.step === 1 || currentStep.step === 9}
      extraClasses={currentStep.step === 2 ? 'md:w-3/4' : ''}
      currentStep={currentStep.step}
      loading={isLoading}
    >
      {!stepsNoProgress.includes(currentStep.step) && (
        <StepsProgress steps={steps} />
      )}
      <Switch
        currentStep={currentStep.step}
        data={currentStep.data}
        onChange={onChangeStep}
      />
    </AuthContainer>
  );
}

export default NonLoggedInClaimView;
