import { useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import {
  AuthContainer,
  LoadingSpinner,
  getAuthProviderName,
} from '@rabbit/elements/shared-components';
import FormCompleteProfile from '../templates/CompleteProfile/FormCompleteProfile';

/* eslint-disable-next-line */
export interface CompleteProfileViewProps {}

export function CompleteProfileView(props: CompleteProfileViewProps) {
  const contextValues = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  if (
    contextValues === null ||
    !contextValues.consumerPersonaData ||
    !contextValues.consumerPersonaId
  )
    return <LoadingSpinner size="xl" isFullScreen />;
  const { consumerPersonaData, consumerPersonaId, user } = contextValues;
  const { providerId } = user.auth.currentUser.providerData[0];

  return (
    <AuthContainer title="Finish account" loading={isLoading}>
      <FormCompleteProfile
        id={consumerPersonaId}
        data={consumerPersonaData}
        authProvider={getAuthProviderName(providerId)}
        setIsLoading={setIsLoading}
      />
    </AuthContainer>
  );
}

export default CompleteProfileView;
