import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Button } from '@rabbit/elements/shared-components';
import {
  ShadowContainer,
  Heading,
  ButtonSocial,
} from '@rabbit/elements/shared-components';
import EmailAuth from '@rabbit/olive/templates/Auth/EmailAuth';
import EmailSent from '@rabbit/olive/templates/Auth/EmailSent';
import ROUTE_NAME from '@rabbit/olive/utils/url-constants';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import sheltaProductPlaceholder from '../../assets/images/shelta-product-placeholder.png';
import { SRStepProps } from '../ServiceRequestView';
import { AppContext } from '@rabbit/app-context';

type SwitchProps = {
  currentStep: number;
  data: any;
  onChange?: any;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const Switch: React.FC<SwitchProps> = ({
  currentStep,
  data,
  onChange,
  setIsLoading,
}) => {
  switch (currentStep) {
    case 1:
      return <Start onChange={onChange} />;
    case 2:
      return (
        <EmailAuth
          onChange={(user: any) => onChange(3, { email: user })}
          data={data}
          setIsLoading={setIsLoading}
          redirectTo={'/service-request/start'}
        />
      );
    case 3:
      return <EmailSent onChange={() => onChange(2)} data={data} />;
    default:
      return null;
  }
};

export default function ServiceRequestAuthView({ setStep }: SRStepProps) {
  const [currentStep, setCurrentStep] = useState({ step: 1, data: null });
  const [loading, setLoading] = useState(false);

  const onChangeStep = (step_in: number, data: any = null) => {
    setLoading(false);
    setCurrentStep({ ...currentStep, step: step_in, data: data });
  };

  return (
    <ShadowContainer loading={loading}>
      {currentStep.step > 1 && (
        <div className="relative px-8 py-4">
          <ChevronLeftIcon
            className="absolute mt-1 h-6 w-6 cursor-pointer"
            onClick={() =>
              setCurrentStep({ step: currentStep.step - 1, data: null })
            }
          />
          <Heading kind="h3" className="text-center">
            {currentStep.step === 2 ? 'Welcome' : 'Confirm your email'}
          </Heading>
        </div>
      )}
      <div className="px-8 py-6">
        <Switch
          currentStep={currentStep.step}
          data={currentStep.data}
          onChange={onChangeStep}
          setIsLoading={setLoading}
        />
      </div>
    </ShadowContainer>
  );
}

function Start({ onChange }: { onChange: any }) {
  const { tenantInfo } = useContext(AppContext);

  return (
    <div className="z-[1] h-full">
      <div className="absolute bottom-0 left-0 z-[0] h-[60%] w-full rounded-tr-[30px] rounded-tl-[30px] bg-gray-50"></div>
      <div className="relative z-[1]">
        <div className="mb-5 mt-4 flex flex-col items-center">
          <img
            src={sheltaProductPlaceholder}
            alt="shelta product placeholder"
            className="w-[340px]"
          />
          <Heading kind="h3">{tenantInfo?.name} service request</Heading>
          <Heading kind="p" className="text-lg">
            powered by iWarranty
          </Heading>

          <div className="mt-[60px] flex flex-col gap-6">
            <Button kind="primary" onClick={() => onChange(2)}>
              Continue with email
            </Button>
            <ButtonSocial type="apple" label="Sign in with Apple" />
            <ButtonSocial type="google" label="Sign in with Google" />
            <p className="font-nunito text-center text-sm font-medium">
              By continuing, you agree to iWarranty’s{' '}
              <a
                href={ROUTE_NAME.TERMS_AND_CONDITIONS}
                target="_blank"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                href={ROUTE_NAME.PRIVACY_POLICY}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
