import {
  ButtonIcon,
  Heading,
  CardGeneral,
  EmptyListCTA,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAME from '../../utils/url-constants';
import { useGetConsumerHoldings } from '@rabbit/bizproc/react';
import noProductsImg from '../../assets/images/service-request-empty.png';
import { UserContext } from '../../context/UserContext';
import { DTHoldingProxy, TableCover } from '@rabbit/data/types';
import {
  calcWarrantyExpiryDate,
  fixCategoryAndSortHoldingsByTCreate,
  getStoneImages,
} from '../../utils/helpers';
import {
  AdjustmentsHorizontalIcon,
  ArrowsUpDownIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
} from '@heroicons/react/24/outline';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import { useTranslation } from 'react-i18next';
import { AppContext } from '@rabbit/app-context';

/* eslint-disable-next-line */
export interface HoldingsViewProps {}
type SortBy =
  | 'Date of registration'
  | 'Date of purchase'
  | 'Name'
  | 'Remaining warranty';
type Order = 'Ascending' | 'Descending';

export function HoldingsView(props: HoldingsViewProps) {
  const { consumerPersonaData, consumerPersonaId } =
    useContext(UserContext) || {};
  const { getAllHoldingProxies } = useGetConsumerHoldings(
    consumerPersonaId || ''
  );
  const [isLoading, setIsLoading] = useState(true);
  const [userHoldings, setUserHoldings] = useState<DTHoldingProxy[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showSorting, setShowSorting] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [appliedOrder, setAppliedOrder] = useState(false);
  const [sortBy, setSortBy] = useState<SortBy>('Date of registration');
  const sorts: SortBy[] = [
    'Date of registration',
    'Date of purchase',
    'Name',
    'Remaining warranty',
  ];
  const [order, setOrder] = useState<Order>('Ascending');
  const orders: Order[] = ['Ascending', 'Descending'];
  const navigate = useNavigate();
  const { tenantInfo } = useContext(AppContext);
  const { t } = useTranslation();
  let tenantType = 'defaultTenants';
  if (t('tenantLink') === 'NUCOVER') tenantType = 'tableTenants';

  useEffect(() => {
    setIsLoading(true);
    if (consumerPersonaData) {
      const getHoldingProxies = async () => {
        const holdings: DTHoldingProxy[] | Error = await getAllHoldingProxies();
        if (holdings instanceof Error) setUserHoldings([]);
        else {
          const finalHoldings = fixCategoryAndSortHoldingsByTCreate(holdings);
          setUserHoldings(finalHoldings);
          setCategories(
            finalHoldings
              .map(
                (i) => i.category || i.self_registration?.category_title || ''
              )
              .filter((v, i, o) => o.indexOf(v) === i)
          );
        }
        setIsLoading(false);
      };

      getHoldingProxies().catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingSpinner size="xs" extraClasses="mt-10" />;

  if (userHoldings.length === 0 && !isLoading)
    return (
      <div className="flex flex-col">
        <div>
          <Heading kind="h3">Your products</Heading>
        </div>
        <div className="mx-auto w-full sm:w-2/3 md:w-1/2 lg:w-1/3">
          <EmptyListCTA
            copy={
              'Nothing to show here yet. Let’s add your first product by clicking on the “add button” below!'
            }
            imgSrc={noProductsImg}
            imgAlt={'Sad face on box'}
            buttonLabel={'Add product'}
            buttonAction={() => navigate(ROUTE_NAME.PRODUCTS_SEARCH)}
          />
        </div>
      </div>
    );

  const DropDownContainer = (props: {
    children: ReactElement;
    title: string;
    onClose: () => void;
  }) => (
    <>
      <div
        className="z-9 fixed left-0 top-0 h-full w-full bg-white opacity-0"
        onClick={props.onClose}
      ></div>
      <div className="fixed left-5 z-10 mt-4 flex h-fit w-[calc(100vw_-_40px)] flex-col rounded-lg bg-white p-5 shadow-lg sm:absolute sm:left-0 sm:w-[448px]">
        <div className="flex items-center justify-between">
          <p className="font-nunito text-lg font-normal text-gray-500">
            {props.title}
          </p>
          <XMarkIcon
            onClick={props.onClose}
            className="h-[18px] w-[18px] cursor-pointer bg-transparent text-gray-400"
          />
        </div>
        {props.children}
      </div>
    </>
  );

  const SelectableRow = (props: {
    title: string;
    selected: boolean;
    onClick: () => void;
  }) => (
    <div
      className="font-nunito flex cursor-pointer gap-4 font-normal"
      onClick={props.onClick}
    >
      <CheckIcon
        className={
          'text-primary-800 h-6 ' + (props.selected ? 'opacity-1' : 'opacity-0')
        }
      />
      <span className="text-gray-500">{props.title}</span>
    </div>
  );

  const selectCategory = (category: string) => {
    const catIndex = selectedCategories.indexOf(category);
    if (catIndex === -1) {
      setSelectedCategories([category, ...selectedCategories]);
    } else {
      setSelectedCategories(selectedCategories.filter((i) => i !== category));
    }
  };

  const applyFilters = () => {
    setAppliedFilters(true);
    setShowFilters(false);
  };

  const resetFilters = () => {
    setSelectedCategories([]);
    setAppliedFilters(false);
    setShowFilters(false);
  };

  const handleOnClose = () => {
    setShowFilters(false);
    if (selectedCategories.length === 0) setAppliedFilters(false);
  };

  const getHoldingTitle = (
    holdingData: DTHoldingProxy | ConsumerHoldingSummaryShape
  ) => {
    if (
      holdingData.self_registration &&
      typeof holdingData.self_registration !== 'boolean'
    ) {
      return holdingData?.self_registration?.title;
    } else return holdingData?.title;
  };

  const sortHandler = (a: DTHoldingProxy, b: DTHoldingProxy) => {
    let aProp: any = new Date(a.created_time);
    let bProp: any = new Date(b.created_time);
    if (sortBy === 'Date of purchase') {
      aProp = new Date(a.purchase_time);
      bProp = new Date(b.purchase_time);
    }
    if (sortBy === 'Name') {
      aProp = getHoldingTitle(a)[0];
      bProp = getHoldingTitle(b)[0];
    }
    if (sortBy === 'Remaining warranty') {
      aProp = calcWarrantyExpiryDate(a.warranties, a.purchase_time);
      bProp = calcWarrantyExpiryDate(b.warranties, b.purchase_time);
    }
    return order === 'Descending'
      ? aProp > bProp
        ? -1
        : 1
      : aProp < bProp
      ? -1
      : 1;
  };

  return (
    <div>
      <div className="mb-5 flex w-full justify-center">
        <div>
          <Heading kind="h3">Your products</Heading>
        </div>
        <div className="ml-auto">
          <ButtonIcon
            type="primary"
            label="Add"
            onClick={() => navigate(ROUTE_NAME.PRODUCTS_SEARCH)}
            iconLeft={false}
          />
        </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="relative z-[50] flex gap-4">
          <div className="relative">
            <ButtonIcon
              label={
                'Filters' +
                (appliedFilters ? `(${selectedCategories.length})` : '')
              }
              Icon={AdjustmentsHorizontalIcon}
              iconLeft={true}
              kind="activeBgLightGreen"
              active={appliedFilters || showFilters}
              onClick={() => {
                setShowFilters(!showFilters);
                setShowSorting(false);
              }}
            />
            {showFilters && (
              <DropDownContainer
                key={'fitler'}
                title="Filter"
                onClose={handleOnClose}
              >
                <div className="font-nunito flex flex-col gap-4 text-black">
                  <span className="mt-5 mb-2">Category</span>
                  <div className="grid w-full grid-cols-2 gap-4">
                    {categories
                      .sort((a, b) => b.length - a.length)
                      .slice(0, 6)
                      .map((category, index) => (
                        <div
                          key={'category-' + index}
                          className={
                            (selectedCategories.indexOf(category) > -1
                              ? 'bg-primary-700 hover:bg-primary-800 text-white'
                              : 'border border-gray-300 text-gray-500 hover:bg-gray-50 ') +
                            'font-nunito cursor-pointer rounded-lg py-2 text-center text-base font-medium capitalize'
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            selectCategory(category);
                            setAppliedFilters(true);
                          }}
                        >
                          {category.length > 0 ? category : 'Others'}
                        </div>
                      ))}
                  </div>
                </div>
              </DropDownContainer>
            )}
          </div>
          <div className="relative">
            <ButtonIcon
              label={appliedOrder ? sortBy : 'Sort'}
              Icon={
                appliedOrder
                  ? order === 'Ascending'
                    ? ArrowUpIcon
                    : ArrowDownIcon
                  : ArrowsUpDownIcon
              }
              iconLeft={true}
              kind="activeBgLightGreen"
              active={showSorting || appliedOrder}
              onClick={() => {
                setShowSorting(!showSorting);
                setShowFilters(false);
              }}
            />
            {showSorting && (
              <DropDownContainer
                key={'sort'}
                title="Sort"
                onClose={() => setShowSorting(false)}
              >
                <div className="font-nunito flex flex-col gap-4 text-black">
                  <span className="mt-5">Sort by</span>
                  <div className="flex w-full flex-col gap-4">
                    {sorts.map((sort, index) => (
                      <SelectableRow
                        key={'sort-' + index}
                        title={sort}
                        selected={sortBy === sort}
                        onClick={() => {
                          setSortBy(sort);
                          setAppliedOrder(true);
                        }}
                      />
                    ))}
                  </div>
                  <span className="mt-1">Order</span>
                  <div className="flex w-full flex-col gap-4">
                    {orders.map((orderItem, index) => (
                      <SelectableRow
                        key={'order-' + index}
                        title={orderItem}
                        selected={order === orderItem}
                        onClick={() => {
                          setOrder(orderItem);
                          setAppliedOrder(true);
                        }}
                      />
                    ))}
                  </div>
                </div>
              </DropDownContainer>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-5 md:grid md:grid-cols-2">
          {userHoldings.length > 0 &&
            userHoldings
              .filter(
                appliedFilters && selectedCategories.length > 0
                  ? (item) =>
                      selectedCategories.indexOf(
                        Array.isArray(item.category)
                          ? item.category[0]
                          : item.category ||
                              item.self_registration?.category_title ||
                              ''
                      ) > -1
                  : () => true
              )
              .sort(sortHandler)
              .map((holding) => {
                if (holding?.deleted) return null;
                const productInfo = holding?.self_registration?.srvInfo
                  ?.productInfo as TableCover;
                const imageUrl = productInfo?.typeofStone
                  ? getStoneImages(productInfo?.typeofStone)
                  : holding.img?.[0] ||
                    holding.self_registration?.img?.[0] ||
                    '';
                return (
                  <CardGeneral
                    key={holding.docid.split('_')[0]}
                    onClick={() =>
                      navigate(
                        `${ROUTE_NAME.PRODUCTS}/${
                          holding.docid.split('_')[0]
                        }/details`
                      )
                    }
                    holdingData={holding}
                    imageUrl={imageUrl}
                    tenantInfo={tenantInfo}
                  />
                );
              })}
        </div>
      </div>
    </div>
  );
}

export default HoldingsView;
