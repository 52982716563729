import {
  Button,
  generateRandomId,
  getCountryByLabel,
  Input,
  InputTypeSelectAddress,
  LoadingSpinner,
  Modal,
} from '@rabbit/elements/shared-components';
import {
  DR_Consumer_Private,
  usePortalDocumentEditor,
} from '@rabbit/data/portal';
import * as Yup from 'yup';
import { Address, DTConsumer_Private } from '@rabbit/data/types';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LIST_COUNTRIES } from '@rabbit/bizproc/react';
import { SelectAddressShape } from '@rabbit/elements/shared-types';
import { AppContext } from '@rabbit/app-context';

interface AccountAddressFormProps {
  personaId: string;
  addressId?: string | null;
  data?: DTConsumer_Private;
  onCloseForm: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const schema = Yup.object({
  label: Yup.string().trim().required('Please enter a label'),
  postcode: Yup.string().trim().required('Please enter a post code'),
  line1: Yup.string().trim().required('Please enter an address'),
  line2: Yup.string().trim(),
  town: Yup.string().trim().required('Please enter a town'),
  state: Yup.string().trim().required('Please enter a state'),
  country: Yup.string().trim().required('Please enter a country'),
  isDefault: Yup.boolean(),
});

function AccountAddressForm({
  personaId,
  addressId,
  onCloseForm,
  setIsLoading,
}: AccountAddressFormProps) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const { body, update, commit, isReady } = usePortalDocumentEditor(
    DR_Consumer_Private,
    personaId
  );
  const { tenantInfo } = useContext(AppContext);
  const formikRef = useRef(null) as any;

  const [selectedAddress, setSelectedAddress] =
    useState<SelectAddressShape | null>({} as SelectAddressShape);

  const existingIndex = Array.isArray(body?.address)
    ? body?.address?.findIndex((obj) => obj.id === addressId)
    : -1;

  const initialValues =
    typeof existingIndex === 'number' &&
    existingIndex !== -1 &&
    Array.isArray(body?.address)
      ? {
          label:
            body?.address && body?.address[existingIndex]?.label
              ? body?.address[existingIndex]?.label
              : '',
          postcode:
            body?.address && body?.address[existingIndex]?.postcode
              ? body?.address[existingIndex]?.postcode
              : '',
          line1:
            body?.address && body?.address[existingIndex]?.line1
              ? body?.address[existingIndex]?.line1
              : '',
          line2:
            body?.address && body?.address[existingIndex]?.line2
              ? body?.address[existingIndex]?.line2
              : '',
          town:
            body?.address && body?.address[existingIndex]?.town
              ? body?.address[existingIndex]?.town
              : '',
          state:
            body?.address && body?.address[existingIndex]?.county
              ? body?.address[existingIndex]?.county
              : '',
          country:
            body?.address && body?.address[existingIndex]?.country
              ? body?.address[existingIndex]?.country
              : '',
          isDefault:
            body?.address && body?.address[existingIndex]?.isDefault
              ? body?.address[existingIndex]?.isDefault
              : false,
        }
      : {
          label: '',
          postcode: '',
          line1: '',
          line2: '',
          town: '',
          state: '',
          country: tenantInfo?.country ?? '',
          isDefault: true,
        };

  const updateDefaultAddress = () => {
    if (body && Array.isArray(body?.address)) {
      const updatedData = body?.address ? [...body.address] : [];
      return updatedData.map((address) => {
        return { ...address, isDefault: false };
      });
    }
  };

  // todo review type
  const addOrEditAddress = (currentAddress: any) => {
    if (body?.address && Array.isArray(body?.address)) {
      const existingIndex = body?.address?.findIndex(
        (obj) => obj.id === currentAddress.id
      );
      let updatedData = body?.address ? [...body.address] : [];
      if (existingIndex !== -1) {
        //check if new address is default and update other address with isDefault boolean false
        if (currentAddress.isDefault)
          updatedData = updateDefaultAddress() ?? [];
        updatedData[existingIndex] = currentAddress;
        return updatedData;
      } else {
        if (currentAddress.isDefault)
          updatedData = updateDefaultAddress() ?? [];
        return [...updatedData, currentAddress];
      }
    } else {
      return [currentAddress];
    }
  };

  const saveAddress = async (address: Address) => {
    if (!body || !isReady) return;

    try {
      update({
        ...body,
        address: addOrEditAddress(address),
      });
      await commit();
      toast.success('Address saved successfully!');
      onCloseForm();
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = async (values: any) => {
    setButtonLoading(true);
    setIsLoading(true);
    try {
      const addressInfo = {
        id: addressId ?? generateRandomId(),
        label: values.label,
        line1: values.line1,
        line2: values.line2,
        company: '',
        town: values.town,
        postcode: values.postcode,
        county: values.state,
        country: values.country,
        isDefault: values.isDefault,
      };
      addressInfo && (await saveAddress(addressInfo));
      setButtonLoading(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!selectedAddress) {
      formikRef.current.resetForm();
      return;
    }

    if (formikRef && formikRef.current && selectedAddress?.postal_code) {
      formikRef.current.setFieldValue('line1', selectedAddress.line_1);
      formikRef.current.setFieldValue('line2', selectedAddress.line_2);
      formikRef.current.setFieldValue('town', selectedAddress.locality);
      formikRef.current.setFieldValue('state', selectedAddress.province_name);
      formikRef.current.setFieldValue('postcode', selectedAddress.postal_code);
      formikRef.current.setFieldValue(
        'country',
        getCountryByLabel(selectedAddress.country_name)?.value ?? ''
      );
    }
  }, [selectedAddress]);

  const [modalDelete, setModalDelete] = useState(false);

  const deleteAndCheckIsDefault = () => {
    if (body?.address && Array.isArray(body?.address)) {
      let updatedData = [...body.address];
      const currentAddress = updatedData.filter((obj) => obj.id === addressId);
      if (currentAddress[0].isDefault) {
        updatedData = updatedData.filter((obj) => obj.id !== addressId);
        if (updatedData.length) updatedData[0].isDefault = true;
        else updatedData = [];
      } else updatedData = updatedData.filter((obj) => obj.id !== addressId);
      return updatedData;
    }
  };

  const onDeleteModal = async () => {
    if (!body || !isReady) return;
    setButtonLoading(true);
    setIsLoading(true);
    try {
      update({
        ...body,
        address: body?.address && deleteAndCheckIsDefault(),
      });
      await commit();
      toast.success('Address removed.');
      onCloseForm();
    } catch (error) {
      console.log('error', error);
    }
    setButtonLoading(false);
    setIsLoading(true);
  };

  const modalSettings = {
    kind: 'pop-up' as const,
    settings: {
      text: 'You are about to delete this address. Are you sure you want to continue?',
      primaryButtonText: 'Yes',
      outlineButtonText: 'No, cancel',
      handleClose: () => setModalDelete(false),
      handlePrimaryClick: () => onDeleteModal(),
      handleOutlineClick: () => setModalDelete(false),
      loading: buttonLoading,
    },
  };

  if (addressId && !body) return <LoadingSpinner size="sm" />;

  return (
    <div>
      <div>
        <Formik
          initialValues={initialValues}
          validateOnChange={false}
          onSubmit={onSubmit}
          validationSchema={schema}
          innerRef={formikRef}
        >
          {(props) => (
            <Form>
              <div className="mt-4 flex flex-col gap-4 pb-2">
                <Input
                  type="select"
                  label="Country"
                  name="country"
                  settings={{
                    options: LIST_COUNTRIES,
                    hint: '*required',
                  }}
                />
                <div>
                  <InputTypeSelectAddress
                    errors={null}
                    onChange={(address) => setSelectedAddress(address)}
                    country={props.values.country}
                  />
                </div>
                <Input
                  type="text"
                  label="Address label"
                  name="label"
                  settings={{
                    hint: '*required',
                    placeholder: 'Home',
                  }}
                />
                <Input
                  type="text"
                  label="Address line"
                  name="line1"
                  settings={{
                    hint: '*required',
                  }}
                />
                <Input
                  type="text"
                  label="Address line 2"
                  name="line2"
                  settings={{}}
                />
                <Input
                  type="text"
                  label="City/Suburb"
                  name="town"
                  settings={{
                    hint: '*required',
                  }}
                />
                <Input
                  type="text"
                  label="State/Territory"
                  name="state"
                  settings={{
                    hint: '*required',
                  }}
                />
                <Input
                  type="text"
                  label="Post code"
                  name="postcode"
                  settings={{
                    hint: '*required',
                  }}
                />
                <Input
                  type="checkbox"
                  name="isDefault"
                  settings={{
                    checkboxLabel: 'Make this my default address',
                    checkboxLabelStyles: 'text-base text-gray-500',
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 py-4">
                <Button
                  type="submit"
                  kind="primary"
                  size="sm"
                  children="Save"
                  loading={buttonLoading}
                  disabled={buttonLoading}
                />
                {addressId && (
                  <Button
                    kind="outline_red"
                    size="sm"
                    children="Delete this address"
                    onClick={() => setModalDelete(true)}
                    disabled={buttonLoading}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {modalDelete && (
        <Modal
          className="h-fit w-[350px] rounded-md bg-white"
          kind={modalSettings.kind}
          settings={modalSettings.settings}
        />
      )}
    </div>
  );
}

export default AccountAddressForm;
