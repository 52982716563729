import {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { ConsumerPersonaChecker } from './ConsumerPersonaChecker';
import {
  useFirebaseAuthentication,
  getOrCreateIdentity,
} from '@rabbit/data/portal';
import { DTConsumer_Private } from '@rabbit/data/types';
import { firebaseAuth } from '@rabbit/firebase/react';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import ROUTE_NAME from '../utils/url-constants';
import { toast } from 'react-toastify';

// TODO: ADD A PROPER TYPE FOR USER!

interface UserInterface {
  user: any;
  consumerPersonaData: DTConsumer_Private | null;
  consumerPersonaId: string | null;
  handleSignout: () => void;
  isLoading: boolean;
}

type UserProviderWrapperProps = {
  children: React.ReactNode;
};

const UserContext = createContext<UserInterface | null>(null);

const UserProviderWrapper = ({ children }: UserProviderWrapperProps) => {
  const { user: authUser, signOut } = useFirebaseAuthentication();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<any>();
  const [consumerPersonaData, setConsumerPersonaData] =
    useState<DTConsumer_Private | null>(null);
  const [consumerPersonaId, setConsumerPersonaId] = useState<string | null>(
    null
  );
  const navigate = useNavigate();

  const handleSignout = useCallback(() => {
    signOut();
    setUser(null);
    setConsumerPersonaData(null);
    setConsumerPersonaId(null);
  }, [signOut]);

  useEffect(() => {
    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');
      // If user is following the Non Logged In Claim (NLIC) flow, this will exist
      const redirectUrl = localStorage.getItem('emailSignIn_redirectUrl');
      const NLIC_StopGetIdentity = window.localStorage.getItem(
        'NLIC_StopGetIdentity'
      );

      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(firebaseAuth, email || '', window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          window.localStorage.removeItem('emailSignIn_redirectUrl');
          if (!NLIC_StopGetIdentity) {
            setUser(result.user);
            navigate(redirectUrl || '/');
          } else {
            window.localStorage.setItem(
              'NLIC_User',
              JSON.stringify(result.user)
            );
            navigate(ROUTE_NAME.NON_LOGGED_CONTINUE_CLAIM);
          }
        })
        .catch(async (error) => {
          console.log(error.code);
          await firebaseAuth.signOut();
          navigate(ROUTE_NAME.AUTH_ERROR);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: Review if this needs to be a useEffect. Just a regular assignment at the top of the component might do the trick
  useEffect(() => {
    authUser.isLoading ? setIsLoading(true) : setIsLoading(false);
  }, [authUser.isLoading]);

  useEffect(() => {
    // If user is following the Non Logged In Claim (NLIC) flow, this will exist and identity shouldn't be created right away
    const NLIC_StopGetIdentity = window.localStorage.getItem(
      'NLIC_StopGetIdentity'
    );

    // Todo need to test if the two loading states are really necessary, but later - dc
    const getter = async () => {
      if (
        !NLIC_StopGetIdentity &&
        !authUser.isLoading &&
        authUser.isSuccess &&
        authUser.data?.uid
      ) {
        try {
          await getOrCreateIdentity();
          setUser(authUser.data);
        } catch (err) {
          console.log(err);
          setUser(null);
        }
      }

      if (!authUser.isLoading && authUser.data == null) {
        setUser(null);
        setConsumerPersonaData(null);
        setConsumerPersonaId(null);
      }
    };
    void getter(); // don't need to wait for this to finish it will update the state

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, authUser.data?.uid]);

  useEffect(() => {
    if (consumerPersonaId === 'NOTALLOWED') {
      void firebaseAuth.signOut();
      setUser(null);
      toast.error(
        'You cannot use this email address to sign in. Please use a different one.'
      );
    }
  }, [consumerPersonaId]);

  const contextValues = useMemo(
    () => ({
      user,
      consumerPersonaData,
      consumerPersonaId,
      handleSignout,
      isLoading,
    }),
    [user, consumerPersonaData, consumerPersonaId, handleSignout, isLoading]
  );

  return (
    <UserContext.Provider value={contextValues}>
      {user && (
        <ConsumerPersonaChecker
          setConsumerPersonaData={setConsumerPersonaData}
          setConsumerPersonaId={setConsumerPersonaId}
        />
      )}
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProviderWrapper };