import { useOliveAPI, useSendEmail } from '@rabbit/bizproc/react';
import {
  Button,
  ButtonSocial,
  Input,
} from '@rabbit/elements/shared-components';
import { useFirebaseAuthentication } from '@rabbit/data/portal';
import classNames from 'classnames';
import { add, getUnixTime } from 'date-fns';
import { Formik, Form } from 'formik';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import * as Yup from 'yup';
import { AppContext } from '@rabbit/app-context';

const styles = {
  label: 'block text-gray-700 text-sm font-bold pt-2 pb-1',
  field:
    'bg-gray-50 border-gray-300 text-gray-500 rounded-lg focus:ring-primary-600 focus:border-primary-600 w-full h-[52px] px-4 py-[14px] placeholder-gray-400',
  button:
    ' bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600',
  errorMsg: 'text-red-500 text-sm',
};

const generateNLICExpiry = () => {
  const inTenMinutes = getUnixTime(add(Date.now(), { minutes: 10 }));
  return `${inTenMinutes}`;
};

interface FormValues {
  email: string;
}

const initialValues: FormValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter an email address'),
  // date: Yup.date()
  //   .max(new Date(), 'Invalid date')
  //   .required(),
});

interface EmailAuthProps {
  onChange: (email: string) => void;
  isNLIC?: boolean;
  data?: any; //todo: type this
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  redirectTo?: string;
}

export function EmailAuth({
  onChange,
  isNLIC,
  setIsLoading,
  redirectTo,
}: EmailAuthProps) {
  const { tenantInfo } = useContext(AppContext);
  const { checkSagePersonaExistenceWithEmail } = useOliveAPI();
  const { signInWithApple, signInWithGoogle, getSignInMethodsForEmail } =
    useFirebaseAuthentication();
  const { SE_Olive_Passwordless_Login } = useSendEmail();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [authErrorMsg, setAuthErrorMsg] = useState<string | null>(null);

  const updateLoadingState = (state: boolean) => {
    setIsLoading(state);
    setLoading(state);
  };

  const doEmailPasswordlessSignIn = async (email: string) => {
    try {
      if (!window.location.origin) return;
      await SE_Olive_Passwordless_Login(
        email ?? '',
        tenantInfo?.emailInfo?.emailSender ?? '',
        tenantInfo?.emailInfo?.emailMainTemplate ?? '',
        tenantInfo?.name ?? '',
        window.location.origin,
        tenantInfo?.emailInfo?.emailTemplateLanguage ?? 'en'
      );
      if (redirectTo) {
        localStorage.setItem('emailSignIn_redirectUrl', redirectTo);
      }
      onChange(email);
      setButtonLoading(false);
    } catch (e) {
      console.log('Failed - ' + e);
      setButtonLoading(false);
      updateLoadingState(false);
    }
  };

  const onSubmit = async (values: FormValues) => {
    updateLoadingState(true);
    setButtonLoading(true);
    if (isNLIC) {
      window.localStorage.setItem('NLIC_StopGetIdentity', generateNLICExpiry());
    }

    if (
      !checkIfAllowedEmailForUser(
        values.email,
        import.meta.env.VITE_FIREBASE_MODE
      )
    ) {
      setAuthErrorMsg('Something went wrong, please try again later...');
      updateLoadingState(false);
      setButtonLoading(false);
      return;
    }

    const { exists } = await checkSagePersonaExistenceWithEmail({
      email: values.email,
    });

    if (exists) {
      setAuthErrorMsg(
        'Email address already in use, please use a different one.'
      );
      updateLoadingState(false);
      setButtonLoading(false);
    } else {
      setAuthErrorMsg(null);
      await doEmailPasswordlessSignIn(values.email);
    }
  };

  return (
    <div className="wrapper">
      <div
        className={classNames('flex flex-col gap-4', {
          'mt-5': isNLIC,
        })}
      >
        <p className="font-nunito text-base">
          Please type in your email or login using Google or Apple to get
          started.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, values }) => {
            return (
              <Form className="flex flex-col gap-3">
                <Input
                  type="email"
                  name="email"
                  settings={{
                    placeholder: 'Please enter your email',
                    hint: '*required',
                  }}
                />
                {authErrorMsg && (
                  <div className="mb-2 text-sm text-red-500">
                    {authErrorMsg}
                  </div>
                )}
                <Button
                  kind="primary"
                  type="submit"
                  disabled={
                    Object.keys(errors).length > 0 ||
                    values.email.length === 0 ||
                    buttonLoading
                  }
                  loading={buttonLoading}
                >
                  Continue with email
                </Button>
              </Form>
            );
          }}
        </Formik>
        <div className="flex justify-center">
          <p className="font-nunito text-base">or</p>
        </div>
        <ButtonSocial
          type="apple"
          label="Sign in with Apple"
          onClick={async () => {
            updateLoadingState(true);
            if (isNLIC) {
              window.localStorage.setItem(
                'NLIC_StopGetIdentity',
                generateNLICExpiry()
              );
              window.localStorage.setItem('NLIC_Federated', 'true');
            }
            try {
              await signInWithApple();
            } catch (error) {
              console.log('res', error);
              updateLoadingState(false);
            }
          }}
        />
        <ButtonSocial
          type="google"
          label="Sign in with Google"
          onClick={async () => {
            updateLoadingState(true);
            if (isNLIC) {
              window.localStorage.setItem(
                'NLIC_StopGetIdentity',
                generateNLICExpiry()
              );
              window.localStorage.setItem('NLIC_Federated', 'true');
            }
            try {
              await signInWithGoogle();
            } catch (error) {
              console.log('res', error);
              updateLoadingState(false);
            }
          }}
        />
      </div>
    </div>
  );
}

export default EmailAuth;

/* -------------------------------------------------------------------------- */
/*                                   Helpers                                  */
/* -------------------------------------------------------------------------- */

const checkIfAllowedEmailForUser = (email: string, firebase_mode: string) => {
  if (
    firebase_mode === 'EMULATOR' ||
    firebase_mode === 'LIVE' ||
    firebase_mode === 'EULIVE' ||
    firebase_mode === 'EUSANDBOX' ||
    firebase_mode === 'EULAUNCHPAD' ||
    firebase_mode === 'USLIVE' ||
    firebase_mode === 'USSANDBOX' ||
    firebase_mode === 'USLAUNCHPAD' ||
    firebase_mode === 'DEMO'
  )
    return true;
  if (
    email.includes('@iwarranty.co') ||
    email.includes('@studiographene.com') ||
    email.includes('@sharklasers.com')
  )
    return true;
  else return false;
};
