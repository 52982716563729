import { useTranslation } from 'react-i18next';
import { Modal, ModalProps } from '@rabbit/elements/shared-components';
import { useContext } from 'react';
import { AppContext } from '@rabbit/app-context';

interface ClaimInitiationWarningProps {
  onClose: () => void;
}

export function ClaimInitiationWarning({
  onClose,
}: ClaimInitiationWarningProps) {
  const { t } = useTranslation();
  const { tenantInfo } = useContext(AppContext);
  const tenantName = tenantInfo?.name || '';
  const tenantContactDetails = tenantInfo?.email || '';
  const formattedText = t('claim.claimInitiationWarning', {
    tenantName,
    tenantContactDetails,
  });

  const modalSettings: ModalProps = {
    kind: 'pop-up',
    settings: {
      title: t('Claim Initiation'),
      text: formattedText,
      primaryButtonText: t('Okay'),
      handlePrimaryClick: onClose,
      handleClose: onClose,
    },
  };
  return <Modal {...modalSettings} />;
}

export default ClaimInitiationWarning;

