import { ComputerDesktopIcon, PlusIcon } from '@heroicons/react/24/outline';
import {
  ClipboardIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import { useGetConsumerHoldings } from '@rabbit/bizproc/react';
import {
  Heading,
  ShadowContainer,
  StepsProgress,
  ButtonIcon,
  CardGeneral,
} from '@rabbit/elements/shared-components';
import { DTHoldingProxy } from '@rabbit/data/types';
import { UserContext } from '@rabbit/olive/context/UserContext';
import { fixCategoryAndSortHoldingsByTCreate } from '@rabbit/olive/utils/helpers';
import { useState, useContext, useEffect } from 'react';
import { SRStepProps } from '../ServiceRequestView';
import emptyStateImg from '../../assets/images/service-request-empty.png';
import { AppContext } from '@rabbit/app-context';

export default function ServiceRequestSelectProduct({ setStep }: SRStepProps) {
  const [userHoldings, setUserHoldings] = useState<DTHoldingProxy[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { consumerPersonaData, consumerPersonaId } =
    useContext(UserContext) || {};
  const { getAllHoldingProxies } = useGetConsumerHoldings(
    consumerPersonaId || ''
  );
  const { tenantInfo } = useContext(AppContext);

  useEffect(() => {
    setIsLoading(true);
    if (consumerPersonaData) {
      const getHoldingProxies = async () => {
        const holdings: DTHoldingProxy[] | Error = await getAllHoldingProxies();
        if (holdings instanceof Error) setUserHoldings([]);
        else {
          const finalHoldings = fixCategoryAndSortHoldingsByTCreate(holdings);
          setUserHoldings(
            finalHoldings.filter((i) => i.active_cases?.length === 0)
          );
          // setCategories(finalHoldings.map(i => i.category || i.self_registration?.category_title || '').filter((v, i, o) => o.indexOf(v) === i))
        }
        setIsLoading(false);
      };

      getHoldingProxies().catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShadowContainer>
      <div className="relative h-full px-8 py-6">
        <Heading kind="h3" className="text-center">
          Service request
        </Heading>
        <div className="mt-4 text-gray-600">
          <StepsProgress
            steps={[
              { Icon: ComputerDesktopIcon, completed: false },
              { Icon: InformationCircleIcon, completed: false },
              { Icon: ClipboardIcon, completed: false },
            ]}
            inline={true}
          />
        </div>
        <div className="mt-6 flex flex-col gap-4">
          <div className="flex w-full justify-between">
            <Heading kind="h4" className="w-full">
              Select or add your product
            </Heading>
            {userHoldings.length > 0 && (
              <ButtonIcon
                Icon={PlusIcon}
                label="Add"
                onClick={() => setStep({ current: 1.1 })}
              />
            )}
          </div>
          <Heading kind="p">
            Please select or add your product below by clicking on the “add
            product” button to get started.
          </Heading>
          <div className="mt-2 flex max-h-[600px] flex-col gap-4 overflow-x-hidden overflow-y-scroll">
            {userHoldings.length > 0 &&
              userHoldings.map((holding) => {
                if (holding?.deleted) return null;
                return (
                  <CardGeneral
                    key={holding.docid.split('_')[0]}
                    onClick={() =>
                      setStep({
                        current: 2,
                        data: {
                          holding,
                          holdingId: holding.docid.split('_')[0],
                        },
                      })
                    }
                    holdingData={holding}
                    imageUrl={
                      holding.img?.[0] ||
                      holding.self_registration?.img?.[0] ||
                      ''
                    }
                    tenantInfo={tenantInfo}
                  />
                );
              })}
            {userHoldings.length === 0 && (
              <div className="flex flex-col items-center gap-6 py-8">
                <img src={emptyStateImg} alt="Nothing to see here" />
                <div className="font-nunito mb-4 text-center text-lg text-gray-500">
                  Nothing to show here yet.
                  <br />
                  Let’s add your first product by clicking
                  <br />
                  on the “add button” below!
                </div>

                <ButtonIcon
                  Icon={PlusIcon}
                  onClick={() => setStep({ current: 1.1 })}
                  label="Add product"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ShadowContainer>
  );
}
