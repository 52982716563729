import {
  ChevronLeftIcon,
  ComputerDesktopIcon,
  InformationCircleIcon,
  ClipboardIcon,
} from '@heroicons/react/24/solid';
import {
  ShadowContainer,
  Heading,
  StepsProgress,
  Button,
  PillsContainer,
  ProductSuggestions,
  SearchBar,
} from '@rabbit/elements/shared-components';
import { DTVendable } from '@rabbit/data/types';
import SearchGallery from '@rabbit/olive/components/molecules/SearchGallery/SearchGallery';
import SearchSlider from '@rabbit/olive/components/molecules/SearchSlider/SearchSlider';
import { SearchResults, SearchVendables } from '@rabbit/search/cherchons';
import { useState, ChangeEvent, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { SRStepProps } from '../ServiceRequestView';
import { useCreateHoldingFromVendable } from '@rabbit/bizproc/react';
import { AppContext } from '@rabbit/app-context';

interface GroupedResults {
  [type: string]: DTVendable[];
}

export default function ServiceRequestSearchProduct({ setStep }: SRStepProps) {
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string[]>([]);
  const [results, setResults] = useState<GroupedResults>();
  const [noResults, setNoResults] = useState<boolean>(false);
  const [vendable, setVendable] = useState<any>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { tenantInfo } = useContext(AppContext);
  const createUserHolding = useCreateHoldingFromVendable(vendable?.docid ?? '');

  const { t } = useTranslation();

  const QUERY_PARAM = 'searchParams';
  const searchParams = new URLSearchParams(window.location.search);

  const updateQueryString = (arr: string[]) => {
    navigate({
      search: arr?.length
        ? createSearchParams({
            searchParams: arr.join(',').toLowerCase(),
          }).toString()
        : '',
    });
  };

  const addValue = (value: string) => {
    if (!searchValue.includes(value)) {
      const newArray = [...searchValue, value];
      if (newArray?.length) updateQueryString(newArray);
      if (noResults) updateQueryString([value]);
    }
  };

  const groupResultsByCategory = (
    data: SearchResults<DTVendable>,
    property: string
  ) => {
    const groupedData = data.items.reduce((groupedData, currentItem: any) => {
      const [propertyValues] = currentItem[property].slice(-1);

      if (propertyValues && !groupedData[propertyValues]) {
        groupedData[propertyValues] = [];
      }

      if (propertyValues && groupedData[propertyValues]) {
        groupedData[propertyValues].push(currentItem);
      }

      return groupedData;
    }, {} as Record<string, DTVendable[]>);

    return groupedData;
  };

  const findResults = async (searchQuery: string) => {
    const data = await SearchVendables(searchQuery, {
      hitsPerPage: 500,
      page: 0,
      facetFilters: [
        {
          name: 'mfr',
          value: `M:${t('tenantLink')}`,
        },
      ],
      //facetFilters: [
      //{ name: 'docid', value: 'sv1-c3bduni3whdq5ipy' },
      //{name: 'series', value: 'umbrella'},
      //],
    });
    const groupedData = groupResultsByCategory(data, 'category');
    //console.log(`findResults`,{data,groupedData});

    if (Object.values(groupedData).length) {
      setResults(groupedData);
      setNoResults(false);
    } else {
      setResults({});
      setNoResults(true);
    }

    return groupedData;
  };

  const handleSubmitSearch = (event?: { key: string }) => {
    if (!searchInput) return;
    if (event && event.key !== 'Enter') return;

    addValue(searchInput);
    setSearchInput('');
  };

  const handleQuickSearch = (value: string) => {
    addValue(value);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    setSearchInput(event.target.value);
  };

  const handleShowMore = async (value: string) => {
    if (!searchValue.includes(value)) {
      setSearchValue([...searchValue, value]);
      addValue(value);
    }

    const filteredResults = Object.keys(results || {}).reduce((acc, key) => {
      const group = (results || {})[key];
      const matchingItems = group.filter((item) => item.series === value);
      if (matchingItems.length > 0) {
        acc[key] = matchingItems;
      }
      return acc;
    }, {} as GroupedResults);

    setResults(filteredResults);
  };

  const handleDeleteSearchItem = (index: number, value: string) => {
    const newSearchValue = [...searchValue];
    newSearchValue.splice(index, 1);
    setSearchValue(newSearchValue);
    if (newSearchValue) updateQueryString(newSearchValue);
  };

  //check if searchValue is updated and then call API if needed
  useEffect(() => {
    if (searchValue.length) {
      findResults(searchValue.join(','))
        .then((data) => console.log('success:', data))
        .catch((err) => console.log(err));
    } else setResults([] as unknown as GroupedResults);
  }, [searchValue]);

  //check if queryString was updated and then updated the search values for results
  useEffect(() => {
    const paramValues = searchParams.get(QUERY_PARAM) || '';
    if (paramValues.split(', ') !== searchValue) {
      const paramsArray = paramValues.split(',');
      if (paramsArray && paramsArray[0]?.length) setSearchValue(paramsArray);
      else setSearchValue([]);
    }
  }, [window.location.search, QUERY_PARAM]);

  const handleVendableSelect = (vendable: any) => {
    setVendable(vendable);
  };

  useEffect(() => {
    if (!vendable) return;
    setLoading(true);
    async function createHolding() {
      const holdingId = await createUserHolding({
        purchase_time: 0,
        serial: '',
        purchase_price: {
          amount: 0,
          currency: tenantInfo?.currency,
        },
        purchase_country: '',
        receipt: [],
        purchase_location: '',
        purchase_location_other: '',
      } as any);
      setStep({ current: 1.3, data: { holdingId, holding: vendable } });
    }

    void (async () => {
      await createHolding();
      setLoading(false);
    })();
  }, [vendable]);

  return (
    <ShadowContainer loading={loading}>
      <div className="relative h-full px-8 py-6">
        <div className="relative">
          <ChevronLeftIcon
            className="absolute mt-1 h-6 w-6 cursor-pointer"
            onClick={() => setStep({ current: 1 })}
          />
          <Heading kind="h3" className="text-center">
            Search
          </Heading>
        </div>
        <div className="mt-6 text-gray-600">
          <StepsProgress
            steps={[
              { Icon: ComputerDesktopIcon, completed: true },
              { Icon: InformationCircleIcon, completed: false },
              { Icon: ClipboardIcon, completed: false },
            ]}
            inline={true}
          />
        </div>
        <div className="mt-10">
          <div className="mt-[42px] max-w-full">
            <SearchBar
              value={searchInput}
              handleChangeCallBack={handleChange}
              submitSearchCallback={handleSubmitSearch}
              placeholder={'Type something here'}
            />
          </div>
          {searchValue.length > 0 && (
            <div className="mt-4">
              <PillsContainer
                values={searchValue}
                onClick={handleDeleteSearchItem}
              />
            </div>
          )}
          <div>
            {noResults && searchValue.length ? (
              <div className="mt-[25px] mb-4">
                <p className="font-nunito font-bold">No products were found.</p>
              </div>
            ) : null}
            {(results && Object.keys(results).length < 1) ||
            !results ||
            noResults ? (
              <>
                <div className="mt-[25px]">
                  <ProductSuggestions
                    submitSearchCallback={handleQuickSearch}
                  />
                </div>
                <div className="mt-8 flex flex-col gap-4">
                  <Button
                    kind="primary"
                    children="Scan product barcode or QR code"
                    onClick={
                      () => 0
                      // onChangeStep
                      //   ? onChangeStep(99, null)
                      //   : navigate(ROUTE_NAME.PRODUCTS_SCAN)
                    }
                  />
                  {
                    <>
                      <Heading kind="p" className="mx-auto">
                        or
                      </Heading>
                      <Button
                        kind="outline"
                        children="Add manually"
                        onClick={() => setStep({ current: 1.2 })}
                      />
                    </>
                  }
                </div>
              </>
            ) : null}

            {results
              ? Object.keys(results).map((key, index) => (
                  <div key={index}>
                    {Object.keys(results).length > 1 ? (
                      <div className="mt-8">
                        <SearchSlider
                          title={key.replace(/-/g, ' ')}
                          actionButtonText="Show All"
                          data={results[key]}
                          onClick={handleShowMore}
                          onItemSelect={(id, holding) =>
                            handleVendableSelect(holding)
                          }
                        />
                      </div>
                    ) : (
                      <div className="py-2 lg:pt-8">
                        <SearchGallery
                          onChangeStep={(_, data) => handleVendableSelect(data)}
                          data={results[key]}
                        />
                      </div>
                    )}
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </ShadowContainer>
  );
}
