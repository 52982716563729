import {
  ComputerDesktopIcon,
  InformationCircleIcon,
  ClipboardIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/solid';
import {
  useGetConsumerHoldings,
  useConsumerHoldingEditor,
  LIST_COUNTRIES,
} from '@rabbit/bizproc/react';
import {
  asyncValidateSchema,
  Button,
  Heading,
  Input,
  LoadingSpinner,
  ShadowContainer,
  StepsProgress,
} from '@rabbit/elements/shared-components';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import { UserUploadedDocument } from '@rabbit/data/types';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';
import { UserContext } from '@rabbit/olive/context/UserContext';
import { fromUnixTime } from 'date-fns';
import { Formik, Form } from 'formik';
import {
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { SRStepProps } from '../ServiceRequestView';
import { AppContext } from '@rabbit/app-context';

const isDemoEnv = import.meta.env.VITE_DEMO;

interface FormValuesShape {
  purchase_date: number;
  purchase_amount: {
    amount: number;
    currency: string;
  };
  store: {
    country: string;
    docid: string;
  } | null;
  serial_number: string;
  purchase_location: {
    docid: string;
    country: string;
  } | null;
  store_not_listed: boolean;
  custom_store_name: string;
  purchase_country: string;
  purchase_proof: UserUploadedDocument[];
}

const validationSchema = Yup.object().shape({
  purchase_date: Yup.date()
    .max(new Date(), 'Please enter a valid date')
    .required('Please select a purchase time')
    .typeError('Please enter a valid date'),
  purchase_amount: Yup.object({
    amount: Yup.number()
      .min(1, 'Please enter a valid amount')
      .required('Please enter a valid amount'),
    currency: Yup.string().trim().required('Currency is required'),
  }).required('Please enter a purchase price'),
  store: Yup.mixed(),
  serial_number: isDemoEnv
    ? Yup.string().trim().required('Please enter a serial number')
    : Yup.string().trim(),
  purchase_location: Yup.mixed(),
  store_not_listed: Yup.boolean(),
  custom_store_name: Yup.string().trim(),
  purchase_country: Yup.string().trim(),
  purchase_proof: Yup.mixed(),
});

export default function ServiceRequestAdditionalInfo({
  setStep,
  data,
}: SRStepProps) {
  const persona = useMyConsumerPersona();
  const { consumerPersonaId } = useContext(UserContext) || {};
  const { getSingleHolding } = useGetConsumerHoldings(consumerPersonaId || '');
  const holdingId = data.holdingId;
  const { body, isReady, update, commit } = useConsumerHoldingEditor(
    consumerPersonaId ?? '',
    holdingId
  );
  const { tenantInfo } = useContext(AppContext);
  const [, setUploadedFiles] = useState<UserUploadedDocument[]>([]);
  const [readyToEdit, setReadyToEdit] = useState(isReady);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [storeLabel, setStoreLabel] = useState<string>(
    'Enter store name & location'
  );

  const {
    public: holding_public,
    manufacturer: holding_manufacturer,
    private: holding_private,
  } = body;

  const [initialValues, setInitialValues] = useState<FormValuesShape>({
    purchase_date: 0,
    purchase_amount: { currency: tenantInfo?.currency ?? 'GBP', amount: 0 },
    purchase_location: null,
    store: null,
    serial_number: '',
    purchase_proof: [],
    store_not_listed: holding_private?.purchase_location_other ? true : false,
    custom_store_name: holding_private?.purchase_location_other ?? '',
    purchase_country: holding_private?.purchase_country ?? '',
  });

  useEffect(() => {
    async function fetchHolding() {
      try {
        const holding = await getSingleHolding(holdingId);
        setInitialValues({
          ...initialValues,
          purchase_date: fromUnixTime(holding?.purchase_time).getTime() || 0,
          store_not_listed: holding?.purchase_location_other ? true : false,
          custom_store_name: holding?.purchase_location_other ?? '',
          purchase_country: holding?.purchase_country ?? '',
        });
        holding?.full_purchase_location &&
          setStoreLabel(
            `${holding?.full_purchase_location?.name} (${holding?.full_purchase_location?.address?.town} - ${holding?.full_purchase_location?.address?.county})`
          );
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }

    void fetchHolding();
  }, []);

  useEffect(() => {
    if (isReady && body) {
      setReadyToEdit(true);
      // setInitialValues({
      //   serial: body.public?.serial || '',
      //   // purchase_location: body.private?.purchase_location || '',
      //   purchase_proof: {} as File,
      //   purchase_time: fromUnixTime(date as number) || 0,
      // });
    }
  }, [isReady, body]);

  const handleUpdate = async (values: FormValuesShape) => {
    // Since the body of the holding already exists, we can trust data to not be undefined

    // If values include a retailer from the database, then purchase_location_other should be cleared - and vice versa
    const newPurchaseLocation =
      values.custom_store_name && values.store_not_listed
        ? ''
        : values.purchase_location?.docid ??
          holding_private?.purchase_location ??
          '';

    const newPurchaseCountry =
      values.custom_store_name && values.store_not_listed
        ? values.purchase_country
        : values.purchase_location?.country ??
          holding_private?.purchase_country ??
          '';

    const newPurchaseLocationOther = values.store_not_listed
      ? values.custom_store_name ??
        holding_private?.purchase_location_other ??
        ''
      : null;

    const {
      public: prevPublic,
      private: prevPrivate,
      manufacturer: prevManufacturer,
    } = body;

    try {
      update({
        public: {
          ...prevPublic!,
          serial: values.serial_number
            ? values.serial_number
            : prevPublic?.serial || '',
          // todo make function to get purchase time, this is messy - dc
          purchase_time: values.purchase_date
            ? Math.floor(values.purchase_date)
            : prevManufacturer?.purchase_time
            ? prevManufacturer?.purchase_time
            : 0,
        },
        private: {
          ...prevPrivate!,
          purchase_location: newPurchaseLocation,
          purchase_location_other: newPurchaseLocationOther,
          purchase_country: newPurchaseCountry,
          purchase_price: values.purchase_amount
            ? values.purchase_amount
            : prevPrivate?.purchase_price,
        },
        manufacturer: {
          ...prevManufacturer!,
          purchase_location: newPurchaseLocation,
          purchase_location_other: newPurchaseLocationOther,
          purchase_country: newPurchaseCountry,
          purchase_time: values.purchase_date
            ? Math.floor(values.purchase_date)
            : prevManufacturer?.purchase_time,
          purchase_price: values.purchase_amount
            ? values.purchase_amount
            : prevManufacturer?.purchase_price,
        },
      });
      await commit();
      setIsSubmitting(false);
      setStep({
        current: 2,
        data: { ...data, holding: data.holding, ...body },
      });
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
      throw new Error('Something went wrong while updating the holding');
    }
  };

  //TODO: CHECK SECOND UPDATE OF THE FILE, THROWING AN ERROR ON BROWSER CONSOLE
  const onSubmit = async (values: FormValuesShape) => {
    if (!readyToEdit) throw new Error('Not ready to edit');
    setIsSubmitting(true);
    void (await handleUpdate(values));
  };

  if (!holding_public || !holding_private || !holding_manufacturer)
    return <LoadingSpinner size="sm" isFullScreen />;

  return (
    <ShadowContainer loading={isSubmitting}>
      <div className="relative h-full px-8 py-6">
        <div className="relative">
          <ChevronLeftIcon
            className="absolute mt-1 h-6 w-6 cursor-pointer"
            onClick={() => setStep({ current: 1 })}
          />
          <Heading kind="h3" className="text-center">
            Additional information
          </Heading>
        </div>
        <div className="mt-6 text-gray-600">
          <StepsProgress
            steps={[
              { Icon: ComputerDesktopIcon, completed: true },
              { Icon: InformationCircleIcon, completed: false },
              { Icon: ClipboardIcon, completed: false },
            ]}
            inline={true}
          />
        </div>
        <div className="mt-6 mb-6 flex flex-col gap-2">
          <Heading kind="h4" className="w-full">
            When and where did you buy your product?
          </Heading>
          <Heading kind="p">
            This information may helps us better identify your product and will
            be stored in your product’s record.
          </Heading>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnChange={true}
          validationSchema={validationSchema}
          validateOnBlur
          validate={asyncValidateSchema(validationSchema)}
        >
          {({ values }) => (
            <Form>
              <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-4">
                  <Input
                    type="datepicker"
                    name="purchase_date"
                    label="Purchase date"
                    settings={{
                      placeholder: 'Purchase date',
                      maxDate: new Date(),
                    }}
                  />
                  <Input
                    type="currency"
                    name="purchase_amount"
                    label="Purchase amount"
                    settings={{
                      id: 'purchase_amount',
                      placeholder: 'Purchase amount',
                      currency: tenantInfo?.currency,
                    }}
                  />
                  <Input
                    type="autocomplete-location-retailer"
                    name="purchase_location"
                    label="Store"
                    settings={{
                      placeholder: 'Enter store name & location',
                      isMulti: false,
                      options: [],
                    }}
                  />
                  <Input
                    type="text"
                    name="serial"
                    label="Serial number"
                    settings={{
                      placeholder: 'Serial number',
                    }}
                  />
                  <Input
                    type="checkbox"
                    name="store_not_listed"
                    settings={{
                      checkboxLabel: 'Store is not listed',
                      checkboxLabelStyles: 'text-base text-gray-500',
                    }}
                  />
                  {values.store_not_listed && (
                    <>
                      <Input
                        type="text"
                        name="custom_store_name"
                        settings={{
                          id: 'custom_store_name',
                          placeholder: 'Enter store name',
                        }}
                      />
                      <Input
                        type="select"
                        label=""
                        name="purchase_country"
                        settings={{
                          options: LIST_COUNTRIES,
                          id: 'purchase_country',
                          placeholder:
                            'The country where the product was purchased',
                        }}
                      />
                    </>
                  )}
                  {isDemoEnv ? (
                    <Input
                      type="text"
                      name="serial_number"
                      settings={{
                        placeholder: 'Serial number',
                        hint: '*required',
                      }}
                    />
                  ) : null}
                </div>
                <OliveFileUploader
                  label="Proof of purchase"
                  identifiers={{
                    category: UploadedFileCategories.ConsumerProofPurchase,
                    docType: {
                      docid: holdingId || '',
                      type: DocTypeShapeTypes.Holding,
                    },
                    personaId: persona?.personaId ?? '',
                  }}
                  onUploadCompleted={(files) => setUploadedFiles(files)}
                  accepts={['image/*', '.pdf']}
                  shouldAutoUpdateDocs={true}
                />
                <Button kind="primary" type="submit">
                  Continue
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ShadowContainer>
  );
}
