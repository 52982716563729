/* eslint-disable @nx/enforce-module-boundaries */
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  Button,
  DesktopContainer,
  Input,
  LoadingSpinner,
  WarrantyInfo,
} from '@rabbit/elements/shared-components';
import {
  ConsumerIssueTypeHookOrigins,
  FileStorageContext,
  useConsumerHoldingEditor,
  useCreateCaseAsConsumer,
  useGetConsumerHoldings,
  useManageConsumerIssueTypes,
  useSendEmail,
} from '@rabbit/bizproc/react';
import { useMyConsumerPersona } from '@rabbit/data/portal';
import classNames from 'classnames';
import { OliveFileUploader } from '@rabbit/olive/components/organisms/upload-wrapper/OliveFileUploader';
import {
  CompletedUploadShape,
  DocTypeShapeTypes,
  UploadedFileCategories,
} from '@rabbit/elements/shared-types';
import { getCompletedUploadsOfCategory } from 'libs/bizproc/react/src/context/file-storage/helpers';
import { getWarrantyInfo } from '@rabbit/olive/utils/helpers';
import { ConsumerHoldingSummaryShape } from '@rabbit/bizproc/client';
import { useTranslation } from 'react-i18next';
import { OurDateTime } from '@rabbit/utils/ts';
import { InfoInterface } from '../warranty-info/HoldingSingleWarrantyInfoView';
import { AppContext } from '@rabbit/app-context';

export interface HoldingSingleClaimViewProps {
  holding_id?: string;
  // If this is defined, then the user is on the NLIC flow
  onChangeStep?: (data: any) => void;
}

interface FormValuesShape {
  issue: string;
  additional_info: string;
}

export function HoldingSingleClaimView({
  holding_id,
  onChangeStep,
}: HoldingSingleClaimViewProps) {
  const { t } = useTranslation();
  const tenantLink = t('tenantLink');
  const [warrantyInfo, setWarrantyInfo] = useState<InfoInterface[]>([]);
  const { config, tenantInfo } = useContext(AppContext);

  const schema = Yup.object({
    issue: Yup.mixed().required('Please select an issue'),
    additional_info: Yup.string(),
    id: Yup.string(),
  });

  const { SE_Olive_Claim_Waiting_For_Review } = useSendEmail();
  const navigate = useNavigate();
  const location = useLocation();
  const formikRef = useRef<FormValuesShape>() as any;
  const holdingId = holding_id ?? location.pathname.split('/')[2];
  const { createCase, serverCreateCase } = useCreateCaseAsConsumer(holdingId);
  const persona = useMyConsumerPersona();
  const { body } = useConsumerHoldingEditor(
    persona?.personaId ?? '',
    holdingId
  );

  const { consumerIssueTypeList } = useManageConsumerIssueTypes(
    ConsumerIssueTypeHookOrigins.OLIVE
  );
  const consumerIssueTypeOptions = !consumerIssueTypeList?.data
    ? []
    : consumerIssueTypeList.data.map((issue) => ({
        id: issue.docid,
        value: issue.docid,
        label: issue.label,
      })) ?? [];

  const { getSingleHolding } = useGetConsumerHoldings(persona?.personaId || '');

  const [holdingSummary, setHoldingSummary] =
    useState<ConsumerHoldingSummaryShape>({} as ConsumerHoldingSummaryShape);

  const [initialValues, setInitialValues] = useState<FormValuesShape>({
    issue: '',
    additional_info: '',
  });

  useEffect(() => {
    const getWarranty = async () => {
      const warranty = await getWarrantyInfo(t('tenantLink'));
      if (warranty) {
        setWarrantyInfo(warranty.warranty_info);
      }
    };
    void getWarranty();
  }, []);

  useEffect(() => {
    (async () => {
      const holding = await getSingleHolding(holdingId);
      if (holding) {
        setHoldingSummary(holding);
        setInitialValues({
          issue: '',
          additional_info: '',
        });
      }
    })().catch((err) => console.error(err));
  }, [persona?.personaId]);

  const { uploadQueueState, moveCompletedUploadsToAttached, isUpdating } =
    useContext(FileStorageContext) || {};

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!body) return <LoadingSpinner size="md" extraClasses="mt-5" />;

  // const selfRegistered = body?.public?.self_registration ?? false;
  // // Disabled until a database of manufacturers and their claim urls is created
  // const manufacturer_claim_url = undefined; // "https://google.com/product/claim"
  // const manufacturer_name = body?.public?.self_registration?.brand ?? null;
  // const warrantor = holdingSummary.warranties
  //   ? BL_Warranty.getLatestWarranty(
  //       holdingSummary.warranties
  //     )?.warrantorLink?.split(':')[1] || undefined
  //   : undefined;

  const onSubmit = async (values: FormValuesShape) => {
    setIsSubmitting(true);
    const { completed } = uploadQueueState ?? {};

    const caseEvidenceUploads: CompletedUploadShape[] =
      getCompletedUploadsOfCategory(
        completed ?? [],
        UploadedFileCategories.ConsumerCaseEvidence
      );

    const caseEvidenceFiles = completed?.flatMap((file) =>
      file.category === UploadedFileCategories.ConsumerCaseEvidence &&
      file?.uploadedFile
        ? [file.uploadedFile]
        : []
    );

    try {
      let res: string | null | undefined | Error = null;

      // if we have a holding id, it's the normal logged in claim, and we use the serverCreateCase function (aka do it from the server)
      // eventually we want to do it all from the server
      if (holdingId) {
        try {
          res = await serverCreateCase({
            consumer_issue_type: consumerIssueTypeOptions.filter(
              (option) => option.value === values.issue
            )[0].label,
            consumer_issue_type_ref: values.issue,
            consumer_issue_description: values.additional_info,
            consumer_proof_of_purchase: body?.private?.receipt?.length
              ? body?.private?.receipt
              : [],
            consumer_claim_evidence: caseEvidenceFiles?.length
              ? caseEvidenceFiles
              : [],
            serial_number: body?.private?.serial,
            serial_number_proof: body?.private?.serial_proof,
          });
        } catch (err: any) {
          const errorMsg = err?.message as string;
          console.error(err);
          if (errorMsg.includes('Case already exists')) {
            toast.error(
              t(
                "You cannot create a claim for this product more than once. If you're having trouble, please contact support."
              )
            );
          } else {
            toast.error(
              t(
                'An error occurred while creating your claim. Please try again later. Details: '
              ) + errorMsg
            );
          }
        }
      } else {
        // if we're on the NLIC flow, we use the old createCase function (aka do it from the client)
        res = await createCase({
          consumer_issue_type: consumerIssueTypeOptions.filter(
            (option) => option.value === values.issue
          )[0].label,
          consumer_issue_type_ref: values.issue,
          consumer_issue_description: values.additional_info,
          consumer_proof_of_purchase: body?.private?.receipt?.length
            ? body?.private?.receipt
            : [],
          consumer_claim_evidence: caseEvidenceFiles?.length
            ? caseEvidenceFiles
            : [],
        });
      }

      const case_time_created = OurDateTime.nowUTCTimestamp();

      // Don't do anything else if the create functions fail
      if (!res) {
        return;
      }

      const claimURL = window.location.origin + '/repairs/' + res;
      await SE_Olive_Claim_Waiting_For_Review(
        persona.data.consumer_private?.email ?? '',
        tenantInfo?.emailInfo?.emailSender ?? '',
        tenantInfo?.emailInfo?.emailMainTemplate ?? '',
        tenantInfo?.name ?? '',
        persona.data.consumer_private?.splitname?.first ??
          persona.data.consumer_private?.fullname ??
          persona.data.consumer_private?.email ??
          '',
        res as string,
        holdingSummary.title,
        case_time_created,
        claimURL,
        tenantInfo?.emailInfo?.emailTemplateLanguage ?? 'en'
      );

      // After creating the case, move the claim evidence files to attached so they don't get cleaned up
      if (moveCompletedUploadsToAttached) {
        if (caseEvidenceFiles && caseEvidenceFiles.length > 0) {
          moveCompletedUploadsToAttached(caseEvidenceUploads);
        }
      }

      if (!onChangeStep) {
        toast.success(t('Created your claim successfully!'));
        navigate('/repairs/' + res);
      } else if (onChangeStep) {
        onChangeStep({
          ...values,
          date_of_claim: new Date(),
          claimId: res,
          purchase_date: body?.public?.purchase_time,
          email: persona.data.consumer_private?.email,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };
  const innerComponent = () => {
    return (
      <div
        className={classNames('flex flex-col', {
          'mt-5': onChangeStep,
        })}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={schema}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {({ errors }) => {
            return (
              <Form className="font-nunito flex flex-col gap-5">
                <Input
                  type="select"
                  name="issue"
                  label={t('Please select an issue*')}
                  settings={{
                    options: consumerIssueTypeOptions,
                    placeholder: t('Select'),
                    id: 'issue',
                    hint: '*required',
                  }}
                />
                <Input
                  label={t('Additional information')}
                  type="text"
                  name="additional_info"
                  settings={{
                    id: 'additional_info',
                    placeholder: t('Write text here…'),
                    allowSpecialCharacter: true,
                  }}
                />

                <div>
                  {tenantLink === 'SHELTA' && (
                    <OliveFileUploader
                      label={t('Proof of purchase')}
                      identifiers={{
                        category: UploadedFileCategories.ConsumerProofPurchase,
                        docType: {
                          docid: holdingId,
                          type: DocTypeShapeTypes.Holding,
                        },
                        personaId: persona?.personaId ?? '',
                      }}
                      shouldAutoUpdateDocs={true}
                      accepts={['image/*', '.pdf']}
                    />
                  )}
                  <OliveFileUploader
                    label={t('Supporting photos')}
                    currentFiles={[]}
                    identifiers={{
                      category: UploadedFileCategories.ConsumerCaseEvidence,
                      docType: {
                        docid: holdingId,
                        type: DocTypeShapeTypes.Case,
                      },
                      personaId: persona?.personaId ?? '',
                    }}
                    accepts={['image/*', 'video/*', '.pdf']}
                  />
                </div>
                <Button
                  kind="primary"
                  type="submit"
                  disabled={
                    Object.keys(errors).length > 0 || isSubmitting || isUpdating
                  }
                  loading={isSubmitting}
                >
                  Submit
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  if (!holdingSummary) return <LoadingSpinner size="md" extraClasses="mt-5" />;

  if (onChangeStep) return innerComponent();

  return config.CLAIMS.CLAIMS_FLOW.CREATE_WITH_WARRANTY_INFO ? (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
      <DesktopContainer
        title={t('Initiate claim')}
        goBack={() => navigate(-1)}
        loading={isSubmitting}
      >
        {innerComponent()}
      </DesktopContainer>
      <div className="h-[566px] overflow-y-auto rounded-lg md:shadow-xl">
        {warrantyInfo?.length > 0 && (
          <div className="md:px-8 md:py-8">
            <div className="hidden text-center md:block">
              <div className="font-caudex mb-6 text-xl font-bold md:text-2xl">
                {tenantInfo?.name === 'NuCover'
                  ? t('Care plan summary')
                  : t('Warranty information')}
              </div>
            </div>
            {warrantyInfo &&
              warrantyInfo.map((data: InfoInterface, index: number) => {
                return <WarrantyInfo key={index} data={data} />;
              })}
          </div>
        )}
      </div>
    </div>
  ) : (
    <DesktopContainer
      title={t('Initiate claim')}
      goBack={() => navigate(-1)}
      loading={isSubmitting}
    >
      {innerComponent()}
    </DesktopContainer>
  );
}

export default HoldingSingleClaimView;
